import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { LoaderWidget } from '@jarvis/react-portal-addons';
import App from './App';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import { ForestFirstScreenDisplayed, publishEvent } from './utils/analytics';

const ForestFirst = props => {
  const [LDProvider, setLDProvider] = useState(null);
  const [fetchingLD, setFetchingLD] = useState(true);
  const [errorLD, setErrorLD] = useState(false);

  const activity = props.properties?.analytics?.activity || 'ForestFirst-v01';
  const locale = props.shell?.v1?.localization?.locale;

  useEffect(() => {
    publishEvent(ForestFirstScreenDisplayed(activity));
  }, [activity]);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const Provider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(locale),
          },
        });
        setLDProvider(() => Provider);
      } catch (err) {
        console.error(err);
        setErrorLD(true);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  if (errorLD) {
    return <App {...props} />;
  }

  return (
    <LDProvider>
      <App {...props} />
    </LDProvider>
  );
};

ForestFirst.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ForestFirst;
