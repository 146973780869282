import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@veneer/core';
import {
  Body, Card, Content, Footer, Header, Title,
} from './styles';

const ProjectWidget = ({
  'data-testid': dataTestId, header, image, body, link, to,
}) => {
  const hasFooter = to && link;
  return (
    <Card
      data-testid={dataTestId}
      appearance="dropShadow"
      content={(
        <>
          <Header backgroundImage={image} />
          <Body>
            <Title
              data-testid="react-smb-forest-first-project-title"
              className="subtitle-regular"
            >
              {header}
            </Title>
            <Content
              className="body"
              dangerouslySetInnerHTML={{ __html: body }}
              hasFooter={hasFooter}
            />
          </Body>
          {hasFooter && (
            <Footer orientation="horizontal">
              <Button
                data-testid="react-smb-forest-first-project-learn-more-button"
                appearance="ghost"
                onClick={() => window.open(to, '_blank')}
                small
              >
                {link}
              </Button>
            </Footer>
          )}
        </>
      )}
    />
  );
};

ProjectWidget.defaultProps = {
  link: undefined,
  to: undefined,
};

ProjectWidget.propTypes = {
  header: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
  body: PropTypes.string.isRequired,
  link: PropTypes.string,
  to: PropTypes.string,
  'data-testid': PropTypes.string.isRequired,
};

export default ProjectWidget;
