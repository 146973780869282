import PropTypes from 'prop-types';
import { path } from 'ramda';

const screenPath = 'ReactSmbForestFirst';
const eventDetailVersion = '1.1.0';

export const publishEvent = event => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window);

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents is not defined');
    return;
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0',
    },
  ]);
};

export const AnalyticsEventPropType = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

// Events

export const ForestFirstScreenDisplayed = activity => ({
  action: 'ScreenDisplayed',
  activity,
  screenPath: '/',
  screenName: 'ForestFirst',
  version: eventDetailVersion,
});

// Events helpers

export const getModuleInitializedEvent = (component, activity) => ({
  action: 'ModuleInitialized',
  activity,
  screenPath,
  screenName: 'ForestFirst',
  controlName: component,
  version: eventDetailVersion,
});

export const getModuleDisplayedEvent = (component, screenName, activity) => ({
  action: 'ModuleDisplayed',
  activity,
  screenPath: `${component}`,
  screenName,
  version: eventDetailVersion,
});
